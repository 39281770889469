import Head from 'next/head'
import React, {useEffect, useState} from "react";
import Footer from "../components/footer";
import Loader from "../components/commons/Loader";
import supportsWebP from 'supports-webp';
import AOS from "aos";
import {ParallaxProvider} from "react-scroll-parallax";

const MainLayout = ({t, children, title, description, canonical, isLoading, alternate, setHash}) => {
    const [origin, setOrigin] = useState('')
    const [host, setHost] = useState('')

    const showShowScrollToTop = () => {
        if (window.scrollY > 20) {
            document.querySelector('.scroll_to_top_container').classList.add('show')
            document.body.classList.add('header_bg')
            document.removeEventListener('scroll', showShowScrollToTop)
            document.addEventListener('scroll', hideShowScrollToTop)
        }
    }

    const hideShowScrollToTop = () => {
        if (window.scrollY < 1) {
            document.querySelector('.scroll_to_top_container') && document.querySelector('.scroll_to_top_container').classList.remove('show')
            document.body.classList.remove('header_bg')
            document.removeEventListener('scroll', hideShowScrollToTop)
            document.addEventListener('scroll', showShowScrollToTop)
        }
    }


    const scrollTo = to => {
        window.scroll({
            top: to,
            behavior: 'smooth'
        })
    }

    const scrollToTop = (anchor) => {
        scrollTo(0)
    }

    useEffect(() => {
        setOrigin(window.location.origin)
        setHost(window.location.host)

        supportsWebP.then(supported => {
            if (supported) {
                document.body.classList.add('webp')
            }
        });

    }, [])

    useEffect(() => {
        document.body.style.overflow = isLoading ? 'hidden' : 'auto';
        AOS.init({});

    }, [isLoading])

    return <>
        <Head>
            <title>{title}</title>
            <link rel="icon" href="/img/icons/favicon.png"/>
            <link rel="apple-touch-icon" sizes="180x180" href="/img/icons/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/img/icons/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/img/icons/favicon-16x16.png"/>
            <link rel="mask-icon" href="/img/icons/safari-pinned-tab.svg" color="#fc5a57"/>
            <meta name="msapplication-TileColor" content="#da532c"/>
            <meta name="theme-color" content="#ffffff"/>
            <meta key="description" name="description" content={description}/>
            <link rel="canonical" href={canonical}/>
            <link rel="alternate" hrefLang="en"
                  href={alternate ? `https://alexthedoge.live/${alternate}` : 'https://alexthedoge.live'}/>
            <link rel="alternate" hrefLang="de"
                  href={alternate ? `https://alexthedoge.live/de/${alternate}` : 'https://alexthedoge.live/de'}/>
            <link rel="alternate" hrefLang="ru"
                  href={alternate ? `https://alexthedoge.live/ru/${alternate}` : 'https://alexthedoge.live/ru'}/>
            {/*<link rel="alternate" hrefLang="cn"*/}
            {/*      href={alternate ? `https://alexthedoge.live/cn/${alternate}` : 'https://alexthedoge.live/cn'}/>*/}
            <link rel="alternate" hrefLang="es"
                  href={alternate ? `https://alexthedoge.live/es/${alternate}` : 'https://alexthedoge.live/es'}/>
            <link rel="alternate" hrefLang="pt"
                  href={alternate ? `https://alexthedoge.live/pt/${alternate}` : 'https://alexthedoge.live/pt'}/>
            <link rel="alternate" hrefLang="tr"
                  href={alternate ? `https://alexthedoge.live/tr/${alternate}` : 'https://alexthedoge.live/tr'}/>
            <link rel="alternate" hrefLang="it"
                  href={alternate ? `https://alexthedoge.live/it/${alternate}` : 'https://alexthedoge.live/it'}/>
            <link rel="alternate" hrefLang="fr"
                  href={alternate ? `https://alexthedoge.live/fr/${alternate}` : 'https://alexthedoge.live/fr'}/>
            <link rel="alternate" hrefLang="x-default"
                  href={alternate ? `https://alexthedoge.live/${alternate}` : 'https://alexthedoge.live'}/>
            <meta property="og:url" content={origin}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content=''/>
            <meta property="og:description" content=''/>
            <meta property="og:image" content={`${origin}/img/main/og.png`}/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content={host}/>
            <meta property="twitter:url" content={host}/>
            <meta name="twitter:title" content=''/>
            <meta name="twitter:description" content=''/>
            <meta name="twitter:image" content={`${origin}/img/main/og.png`}/>
            <meta name='description' content={description}/>
        </Head>
        <ParallaxProvider>
            <>
                {children}
                <Footer
                    t={t}
                />
                <Loader
                    isLoading={isLoading}
                />
            </>
        </ParallaxProvider>
    </>
}

export default MainLayout

