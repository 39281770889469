import {useParallax} from "react-scroll-parallax";
import React from "react";

const randomInteger = (min, max) => {
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
}

export const makeParallax = (images) => {
    return images.map((el, i) => {
        const {ref} = useParallax({speed: randomInteger(3, 13)});
        return <img alt={el} key={el + i} ref={ref} className={`img_${i + 1}`}
                    src={`/img/pages/home/paralax/${el}.svg`}/>
    })
}

export const makeStaticParallax = (images, path, speed) => {
    return images.map((el, i) => {
        const {ref} = useParallax({speed: speed.length > 1 ? randomInteger(speed[0], speed[1]) : speed[0]});
        return <picture
            key={el.name + i}
        >
            <source
                type="image/webp"
                media="(max-width: 768px)"
                srcSet={`${path}${el.name}_small.webp 1x, ${path}${el.name}_small_x2.webp 2x`}
            />
            <source
                type="image/webp"
                media="(min-width: 769px)"
                srcSet={`${path}${el.name}.webp 1x, ${path}${el.name}_x2.webp 2x`}
            />
            <source
                media="(max-width: 768px)"
                srcSet={`${path}${el.name}_small.png 1x, ${path}${el.name}_small_x2.png 2x`}
            />
            <source
                media="(min-width: 769px)"
                srcSet={`${path}${el.name}.png 1x, ${path}${el.name}_x2.png 2x`}
            />
            <img
                alt={el.name}
                key={el.name + i}
                ref={ref}
                className={el.className}
                srcSet={`${path}${el.name}.png 1x, ${path}${el.name}_x2.png 2x`}
                width={el.width} height={el.height}
                src={`${path}${el.name}.png`}
            />
        </picture>
    })
}
