import React from 'react';
import Link from "next/link";
import {makeStaticParallax} from "../../helpers/makeParalax";

const Footer = ({t, setHash}) => {

    const footer = t('common:footer', {}, {returnObjects: true});

    const SocialItem = ({item, i}) => {
        return <li
            className='social_items_item'>
            <Link prefetch={false}
                  href={item.url}
            >
                <a
                    className='social_items_item_icon'
                    target={'_blank'}
                    aria-label={item.name}
                >
                    <svg width={item.icon.w} height={item.icon.h} viewBox={`0 0 ${item.icon.w} ${item.icon.h}`}
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d={item.icon.d} fill="white"/>
                    </svg>
                </a>
            </Link>
        </li>
    }

    const NavItem = ({item}) => {
        return <li className='footer_section_nav_item'>
            <Link
                prefetch={false}
                href={
                    item.url === '/whitepaper' ? process.env.NEXT_PUBLIC_WHITE_PAPER_URL :
                        item.url === '/buy' ? process.env.NEXT_PUBLIC_SIGN_UP_URL :
                            item.url === 'audits' ? process.env.NEXT_PUBLIC_SOLID_PROOF_AUDIT_URL :
                                item.url
                }
            >
                <a
                    className='footer_section_nav_item_link'
                    target={item.target}
                >
                    {item.name}
                </a>
            </Link>
        </li>
    }

    const clouds = [
        {
            name: 'cloud_left',
            className: 'cloud cloud_left_1',
            width: '393',
            height: '179'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_2',
            width: '271',
            height: '123'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_3',
            width: '271',
            height: '123'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_4',
            width: '271',
            height: '123'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_5',
            width: '271',
            height: '123'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_6',
            width: '271',
            height: '123'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_7',
            width: '296',
            height: '135'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_8',
            width: '315',
            height: '144'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_9',
            width: '315',
            height: '144'
        }, {
            name: 'cloud_left',
            className: 'cloud cloud_left_10',
            width: '315',
            height: '144'
        },
    ]


    return (
        <footer className='footer_section' id='footer'

        >
            <div className="container">
                <div className="footer_section_wrapper">
                    {makeStaticParallax(clouds, '/img/pages/home/progress/', [6, 16])}
                    <Link href={'/'}>
                        <a>
                            <picture>
                                <source
                                    srcSet={`/img/main/logo_text.webp`}
                                    type="image/webp"
                                />
                                <img
                                    // loading="lazy"
                                    className="footer_section_logo"
                                    src={"/img/main/logo_text.svg"}
                                    alt="Alex The Doge Logo"
                                />
                            </picture>
                        </a>
                    </Link>
                    <div
                        data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-out"
                        data-aos-delay="0"
                        data-aos-offset='-100'
                    >
                        <nav>
                            <ul className='footer_section_nav'>
                                {footer.nav.map(item => {
                                    return <NavItem
                                        key={item.name}
                                        item={item}
                                    />
                                })}
                            </ul>
                        </nav>
                        <h4 className='social_items_title'>{footer.title}</h4>
                        <ul className='social_items'>
                            {footer.social.map((item, i) => {
                                return <SocialItem
                                    i={i}
                                    key={item.name}
                                    item={item}
                                />
                            })}
                        </ul>


                        <p className='footer_section_copywright'>
                            {footer.copywright_1} {new Date().getFullYear()}{footer.copywright_2}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;