import React from 'react';

const Loader = ({isLoading}) => {
    return (
        <div className={`page_loader ${isLoading ? 'loading' : ''}`}>
            <div className="page_loader_content">
                <picture>
                    <source srcSet="/img/main/big_logo.webp" type="image/webp"/>
                    <img width='100' height='100' className='loader_img' alt='alex doge logo'
                         src='/img/main/big_logo.png'/>
                </picture>
            </div>
        </div>
    );
};

export default Loader;